import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTop from 'common/ui/ScrollToTop';
import { ENV_NAME, GOOGLE_OAUTH_CLIENT_ID } from 'config/env.config';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import store from './state/store';

import { ConfigProvider } from 'antd';
import 'tailwindcss/tailwind.css';
import './styles/custom.css';

const App = () => {
  const isProd = ENV_NAME === 'production';
  const theme = !isProd
    ? {}
    : {
        token: {
          colorPrimary: '#cb2b83',
        },
      };

  console.log('ENV_NAME', ENV_NAME);
  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <React.StrictMode>
        <HelmetProvider>
          <Provider store={store}>
            <ConfigProvider theme={theme}>
              <BrowserRouter>
                <ScrollToTop />
                <AppRouter />
              </BrowserRouter>
            </ConfigProvider>
          </Provider>
        </HelmetProvider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
};

export default App;
